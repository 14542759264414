<template>
  <div>
    <div class="foshi-top"></div>
    <van-row type="flex" justify="center">
      <van-col span="23">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
          <div
            class="news-list"
            v-for="(item,index) in list"
            :key="index"
            @click="todetail(item.id)"
          >
            <van-row type="flex" justify="space-between">
              <van-col class="news-title" span="14">{{item.title}}</van-col>
              <van-col span="8">
                <img class="news-image" :src="item.headimgurl" />
              </van-col>
            </van-row>
            <div class="news-author">
              <van-row>
                <van-col span="13">
                  <van-row type="flex" justify="space-between">
                    <van-col>{{item.author}}</van-col>
                    <van-col>{{item.createtime}}</van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>
          </div>
        </van-list>
      </van-col>
    </van-row>
    <!-- <div>底部地基</div> -->
    <div class="wrap-bottom"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      pageindex: 1,
      loading: false,
      finished: false,
    };
  },
  created() {
    this.initialization();
  },
  methods: {
    onLoad() {
      this.getlist();
      this.pageindex++;
    },
    initialization() {
      this.list = [];
      this.pageindex = 1;
      this.loading = true; //下拉加载中
      this.finished = false; //下拉结束
      if (this.loading) {
        this.onLoad();
      }
    },
    getlist() {
      this.$api.article.getarticlelist(2, this.pageindex).then((res) => {
        if (res.data.data.list.length == 0) {
          this.finished = true;
        } else {
          this.list.push(...res.data.data.list);
          console.log(this.list);
        }
        this.loading = false;
      });
    },
    todetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.foshi-top {
  height: 0.8rem;
}
</style>